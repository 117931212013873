import { sort } from "@lib/utils"
import { graphql, useStaticQuery } from "gatsby"

const useVideos = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiVideo(
        filter: { publish: { eq: true } }
        sort: { fields: date, order: DESC }
      ) {
        nodes {
          title
          video_url
          date(formatString: "MMMM DD, YYYY")
          category {
            strapi_json_value
            id
          }
          ggfx_results {
            ...STRAPI_GGFX_RESULTFragment
          }
          image {
            url
          }
        }
      }
    }
  `)

  const videos = data?.allStrapiVideo?.nodes

  // if (videos) {
  //   videos.sort((a, b) => sort(a?.rank, b?.rank))
  // }

  return videos
}

export default useVideos
